<template>
    <div class="max-w-xl lg:max-w-lg mx-auto mt-10">
        <h2
            class="text-3xl sm:text-4xl font-gothic tracking-tight text-center text-white"
        >
            Get Weekly Job Alerts!
        </h2>
        <p class="mt-4 text-lg leading-8 text-gray-300 text-center">
            Stay updated with the latest remote job opportunities in Dubai.
            Subscribe now and never miss a job post.
        </p>
        <form
            v-on:submit.prevent="emailSubmit"
            class="mt-6 flex flex-wrap justify-center gap-4 max-w-lg mx-auto"
        >
            <label for="email-address" class="sr-only">Email address</label>
            <input
                v-model="emailForm.email"
                id="email-address"
                name="email"
                type="email"
                autocomplete="email"
                required
                class="min-w-0 flex-auto rounded-md border-2 border-cyan-500 bg-gray-900/80 px-3.5 py-4 text-white shadow-md shadow shadow-bluewood-500 ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-lg sm:leading-6"
                placeholder="Enter your email"
            />
            <Button
                :disabled="emailForm.processing"
                type="submit"
                class="uppercase"
                >Subscribe</Button
            >
        </form>

        <Avatars class="dark" />
    </div>
</template>

<script setup>
import Button from "@/Components/Guest/Button.vue";
import Avatars from "@/Components/Guest/Avatars.vue";
import { useEmailForm } from "@/utils/subscribe.js";
import { inject } from "vue";

const { emailForm, emailSubmit } = useEmailForm();
</script>
